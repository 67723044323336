import { createRouter, createWebHistory } from 'vue-router'

import qs from 'qs'
import { IPortalRouter } from '@zephyr/framework/utils'
import { createRoutes } from './routes'

const stringify = (str) => {
  return qs.stringify(str, { encode: false })
}

declare module 'vue-router' {
  interface RouteMeta {
    title?: string;
  }
}

export const initRouter = async (portalRoutes: IPortalRouter[]) => {
  const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        return new Promise((resolve, _reject) => {
          setTimeout(() => {
            resolve({ el: to.hash })
          }, 500)
        })
      }
      if (savedPosition) {
        return savedPosition
      }
      if (to.meta.noScroll && from.meta.noScroll) {
        return {}
      }
      return { top: 0 }
    },
    routes: await createRoutes(portalRoutes),

    parseQuery: qs.parse,
    stringifyQuery: stringify
  })

  // Находим роут по его имени
  // const route = router.options.routes.find(route => route.name === 'user')

  // // Добавляем хук к найденному роуту
  // route.beforeEnter = (to, from, next) => {
  // // ваша логика проверки здесь
  // }

  // // Обновляем маршруты
  // router.matcher = new VueRouter({ routes: router.options.routes }).matcher

  return router
}